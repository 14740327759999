import styles from './Poliza.module.css';
import whatsappIcon from '../assets/WA.svg';

export default function Poliza() {
    return (
        <div className='page'>
            <h1 className='display-small'>Pólizas de vida</h1>

            <div className={styles.card}>
                <div className={styles.header}>
                    <p className='title-large' style={{ color: 'var(--color-primary)' }}>Básica</p>
                    <h6 className='title-small'>VTM-818</h6>
                </div>
                <div className={styles.price}>
                    <p className='display-small'>₡2900</p>
                    <p className='label-medium'>por mes</p>
                </div>
                <p className='body-large'>
                    Los afiliados y las afiliadas de ANEP pueden acceder a una póliza de vida por un monto asegurado de 5 millones de colones.
                </p>
                <a href="https://anep.cr/polizas-colectiva-de-vida-vtm-818/" target='_blank'>
                    <button className="filled-button" style={{
                        width: '100%'
                    }}>
                        <span className='material-symbols'>
                            open_in_new
                        </span>
                        Conocer mas
                    </button>
                </a>
            </div>

            <div className={styles.card}>
                <div className={styles.header}>
                    <p className='title-large' style={{ color: 'var(--color-tertiary)' }}>Voluntaria</p>
                    <h6 className='title-small'>VTM-738 / VTM-739</h6>
                </div>
                <div className={styles.price}>
                    <p className='display-small'>₡4560</p>
                    <p className='label-medium'>por mes</p>
                </div>
                <p className='body-large'>
                    Adquiere una poliza voluntaria adicional desde 5 hasta 40 millones. Contactanos para valorar la opcion que mejor se adapte a tus necesidades.
                </p>
                <a href="https://anep.cr/polizas-voluntarias/" target='_blank'>
                    <button className="filled-button" style={{
                        width: '100%',
                        backgroundColor: 'var(--color-tertiary)',
                        color: 'var(--color-on-tertiary)'
                    }}>
                        <span className='material-symbols'>
                            open_in_new
                        </span>
                        Conocer mas
                    </button>
                </a>
            </div>

            {/* <div className="poliza-card">
                <h6>VTM-525</h6>
                <h4 style={{ marginRight: '2rem' }}><ShieldTaskRegular style={{ marginRight: '.5rem' }} fontSize={24} />Póliza Básica colectiva de vida</h4>
                <p>Actualmente los afiliados y las afiliadas de ANEP tienen una póliza de vida por un monto asegurado de 4 millones de colones.</p>
                <button className="filled-button" onClick={() => { window.location.href = "https://sites.google.com/amubri.voto/polizas-de-vida/p%C3%A1gina-principal" }}>Saber mas</button>
            </div>

            <div className="poliza-card">
                <h6>VTM-738</h6>
                <h4><ShieldTaskRegular style={{ marginRight: '.5rem' }} fontSize={24} />Póliza Voluntaria</h4>
                <p>La afiliación de ANEP tiene la posibilidad de suscribirse a pólizas colectivas de caracter voluntario para aumentar la cobertura del seguro de vida.</p>
                <button className="filled-button" onClick={() => { window.location.href = "https://sites.google.com/amubri.voto/polizas-de-vida/p%C3%A1gina-principal" }}>Saber mas</button>
            </div> */}

            <button className="outlined-button" style={{ width: '100%', marginTop: '2rem' }} onClick={() => { window.location.href = "https://wa.me/50684767111/?text=Hola, quiero saber más sobre las pólizas" }}>
                <img src={whatsappIcon} style={{ marginRight: '0.5rem' }} />
                8476 7111
            </button>
        </div>
    );
}
