import './Post.css';
// import { ShareRegular, ThumbLikeRegular, ThumbLikeFilled } from "@fluentui/react-icons";
import { getFirestore, doc, deleteDoc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';

export default function Post({ title, description, thumbnail, likesCounter, id, userId, authState }) {
    const [liked, setLiked] = useState(null);

    const [counter, setCounter] = useState(likesCounter);

    const db = getFirestore();

    useEffect(() => {
        console.log(userId);
        var docRef = doc(db, 'news', id);
        onSnapshot(docRef, (doc) => {
            setCounter(doc.data().likesCounter);
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (userId) {

                var docRef = doc(db, "news/" + id + "/likes", userId);
                onSnapshot(docRef, (doc) => {
                    console.log(doc.data())
                    if (doc.data() === undefined) {
                        setLiked(false);
                    } else {
                        setLiked(true);
                    }
                });

            }
        }
        fetchData();

    }, [userId]);

    const like_dislike = async () => {
        if (authState) {
            try {
                if (liked) {
                    setCounter(counter - 1);
                    await deleteDoc(doc(db, "news/" + id + "/likes", userId));
                    console.log('Document deleted');

                } else {
                    setCounter(counter + 1);
                    await setDoc(doc(db, "news/" + id + "/likes", userId), {
                        uid: userId,
                    });
                    console.log('Document success');

                }

            } catch (error) {
                console.log('Error creating the document', error)
            }
        } else {
            alert('Solo puedes dar me gusta a una publicacion si eres afiliado a la ANEP.');

        }

    }

    const share = () => {
        const shareData = {
            title: title,
            text: description,
            url: 'https://anep.app/noticias',
            // url: 'https://anep.app/news?id='+id,
        }
        navigator.share(shareData);
    }

    return (
        <div className="post-card">
            <img src={thumbnail} className="post-card-img" />
            <div className="post-card-content">
                <h2 className='title-large'>{title}</h2>
                <p>{description}</p>
                <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                    <button className="icon-button" onClick={like_dislike}>{liked ? <span className='material-symbols active' style={{color: 'var(--color-error)'}}>
                        favorite
                    </span> : <span className='material-symbols'>
                        favorite
                    </span>}</button>
                    <p><b>{counter}</b></p>
                    <button className="icon-button" onClick={share}>
                        <span className='material-symbols'>
                            share
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}