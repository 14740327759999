import React, { useEffect, useState } from 'react';
import './App.css';

// LIBRARIES
import { CallRegular, CheckboxPerson24Regular, ShareScreenPersonOverlayRegular, NotepadPersonRegular } from "@fluentui/react-icons";
import { Routes, Route, useParams, useNavigate, Link } from "react-router-dom";

// NAVIGATION
import TopAppBar from './navigation/TopAppBar';
import NavigationBar from './navigation/NavigationBar';
import NavigationRail from './navigation/NavigationRail';
import NavigationDrawer from './navigation/NavigationDrawer';
import ModalNavigationDrawer from './navigation/ModalNavigationDrawer';

// ROUTES

import Inicio from './routes/Inicio';

import Signup from './routes/Signup';
import EmailSent from './routes/EmailSent';

import Noticias from './routes/Noticias';
import Convenios from './routes/Convenios';
import Carne from './routes/Carne';

// import Legal from './routes/Legal';
import CasosLegales from './routes/CasosLegales';
import Directorio from './routes/Directorio';

import Recreacion from './routes/Recreacion';

import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
// import { getAnalytics, isSupported } from "firebase/analytics";
// import { getMessaging, onMessage, getToken, isSupported as isMessagingSupported } from "firebase/messaging";

// import whatsappIcon from './assets/WA.svg';
import Chat from './routes/Chat';
import Calendario from './routes/Calendario';
import Poliza from './routes/Poliza';



const firebaseConfig = {
  apiKey: "AIzaSyBbZwtBUQ02kwLQmxgkruVG3aIGc_Ooe0Q",
  // authDomain: "anep-d2f3a.firebaseapp.com",
  authDomain: "anep.app",
  projectId: "anep-d2f3a",
  storageBucket: "anep-d2f3a.appspot.com",
  messagingSenderId: "715798290168",
  appId: "1:715798290168:web:1a354c80ad32d629d7650d",
  measurementId: "G-8K90QX2HP8"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
// Analytics if its supported
// const analytics = isSupported ? getAnalytics(app) : null;
// const messaging = getMessaging(app);

// if (isMessagingSupported()) {
//   navigator.serviceWorker.register('/service-worker.js')
//     .then((registration) => {
//       messaging.useServiceWorker(registration);

//       onMessage(messaging, (payload) => {
//         console.log('Message received. ', payload);
//         // Customize notification here
//       });
//     });
// }

export default function App() {
  const [authState, setAuthState] = useState(null);
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userVerified, setUserVerified] = useState(null);
  const [userInstitution, setUserInstitution] = useState('');

  const [cedula, setCedula] = useState('');
  const [fullName, setFullName] = useState('');
  const [institucion, setInstitucion] = useState('');

  const [fcmToken, setFcmToken] = useState('');

  useEffect(() => {

    // if (isMessagingSupported()) {
    //   getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' })
    //     .then((currentToken) => {
    //       if (currentToken) {
    //         console.log('current token for client: ', currentToken);
    //         setFcmToken(currentToken);
    //         // Track the token -> send it to your server via HTTP/HTTPS request
    //       } else {
    //         console.log('No registration token available. Request permission to generate one.');
    //         // Show permission request.
    //       }
    //     }).catch((err) => {
    //       console.log('An error occurred while retrieving token. ', err);
    //       // Check if the token was not found and ignore the error if so
    //       if (err.code !== 'messaging/token-unsubscribe-failed') {
    //         // Handle other potential errors
    //       }
    //     });
    // } else {
    //   console.log('Messaging not supported');
    // }

    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    //   // Customize notification here
    //   var notificationTitle = 'Background Message Title';
    //   var notificationOptions = {
    //     body: 'Background Message body.',
    //     icon: '/logo192.png'
    //   };

    //   // Show notification
    //   if (Notification.permission === 'granted') {
    //     new Notification(notificationTitle, notificationOptions);
    //   }
    // });

  }, [authState]);

  useEffect(() => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        setUserId(uid);
        setAuthState(true);
        console.log('User is signed in', uid);

        const docRef = doc(db, "users", uid);

        const subtouser = onSnapshot(docRef, async (doc) => {
          if (doc.exists()) {
            console.log("Current user data: ", doc.data());
            // console.log("Current user data: ", doc.data().approved);

            if (doc.data().approved === undefined) {
              setUserVerified(false);
            } else {
              setUserVerified(doc.data().approved);
            }

            // setUserVerified(doc.data().approved);

            const docSnap = await getDoc(doc.data().ref);
            if (docSnap.exists()) {
              console.log("Document data afiliado:", docSnap.data());
              setInstitucion(docSnap.data().institucion);
              setFullName(docSnap.data().nombre + " " + docSnap.data().apellidos);
              setCedula(docSnap.data().cedula);
              setUserEmail(docSnap.data().email);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          }

        });

        // const docSnap = await getDoc(docRef);
        // if (docSnap.exists()) {

        //   console.log("Document data:", docSnap.data());

        //   const subtoafiliate = onSnapshot(docSnap.data().ref, (doc) => {
        //     console.log("Current data afiliado: ", doc.data());
        //     setFullName(doc.data().nombre + " " + doc.data().apellidos);
        //     setCedula(doc.data().cedula);
        //   });

        // } else {
        //   console.log("No such document!");
        // }

      } else {
        // setAuthState(false);
        // setUserVerified(true);
        setAuthState(false);
        console.log('User is signed out');
      }
    });
  }, []);



  return (
    <div className="app">

      <TopAppBar />

      <ModalNavigationDrawer />

      <aside>
        <NavigationRail />
        <NavigationDrawer />
      </aside>

      <main>
        <Routes>
          <Route path="/" element={<Inicio authState={authState} userId={userId} userVerified={userVerified} fullName={fullName} cedula={cedula} fcmToken={fcmToken} />} />
          <Route path="signup" element={<Signup authState={authState} userVerified={userVerified} />} />
          <Route path="signup/emailsent" element={<EmailSent authState={authState} />} />
          <Route path="signup/afiliate" element={<Afiliate />} />
          <Route path="signup/vincular" element={<Vincular authState={authState} userId={userId} />} />

          <Route path="calendario" element={<Calendario />} />

          <Route path="noticias" element={<Noticias authState={authState} userId={userId} />} />

          <Route path="convenios" element={<Convenios authState={authState} />} />
          <Route path="convenios/:id" element={<Convenio authState={authState} />} />

          <Route path="carne" element={<Carne authState={authState} userVerified={userVerified} userId={userId} fullName={fullName} cedula={cedula} institucion={institucion} />} />

          <Route path="legal" element={<CasosLegales authState={authState} userVerified={userVerified} cedula={cedula} institucion={institucion} />} />
          <Route path="legal/chat" element={<Chat authState={authState} userVerified={userVerified} userId={userId} userEmail={userEmail} />} />
          <Route path="directorio" element={<Directorio authState={authState} userVerified={userVerified} />} />
          <Route path="poliza" element={<Poliza />} />

          <Route path="recreacion" element={<Recreacion />} />

          <Route path="restringido" element={<Restringido />} />
        </Routes>
      </main>

      <NavigationBar />

    </div>
  );

}

function Restringido() {
  return (
    <div className='page'>
      <div className='signup-card'>
        <ShareScreenPersonOverlayRegular fontSize={36} />
        <h2>Acceso exclusivo para afiliados</h2>
        <p>La pagina a la que deseas acceder esta disponible unicamente para afiliados de la ANEP.</p>
        <Link to="/"><button className='filled-button'>Volver a inicio</button></Link>
      </div>

    </div>
  );
}

function Convenio() {
  const [brand, setBrand] = useState('');
  const [promo, setPromo] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState('');
  const [phone, setPhone] = useState('');
  let { id } = useParams();
  console.log(id);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "agreements", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setBrand(docSnap.data().brand);
        setPromo(docSnap.data().promo);
        setDescription(docSnap.data().description);
        setLogo(docSnap.data().logo);
        setPhone(docSnap.data().phone);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    fetchData();

  }, []);

  return (
    <div className="page">

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1.5rem',
        background: 'var(--color-surface)',
        borderRadius: 'var(--shape-corner-large)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'

      }}>
        <img src={logo} className="agreement-page-img" width='156px' />
        <h1 className='headline-small'>{brand}</h1>
        <h2 className='display-medium'>{promo}</h2>
        <p>{description}</p>
        <button className="filled-button" onClick={() => { window.location.href = 'tel:+506' + phone }}><CallRegular fontSize={24} />{phone}</button>
      </div>

    </div>
  );
}

function Vincular({ userId }) {
  const [cedulaInput, setCedulaInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [success, setSuccess] = useState(false);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [institucion, setInstitucion] = useState('');
  const [dependencia, setDependencia] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [puesto, setPuesto] = useState('');

  let navigate = useNavigate();

  const request = async e => {
    e.preventDefault();

    setErrorMessage('');
    if (cedulaInput.length === 0) {
      setErrorMessage('Debes ingresar un numero');
    } else {
      const docRef = doc(db, "usuarios", cedulaInput);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:",);
        var d = docSnap.data();
        setId(d.id)
        setName(d.nombre);
        setLastname(d.apellidos);
        setInstitucion(d.institucion);
        setDependencia(d.dependencia);
        setDepartamento(d.departamento);
        setPuesto(d.puesto);
        setSuccess(true);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setErrorMessage("No se encontro ningun afiliado con este numero de cedula");
      }
    }
  }

  const complete = async () => {
    var dbRef = doc(db, "usuarios", cedulaInput);
    var dataObj = {
      approved: true,
      ref: dbRef
    };
    try {
      await updateDoc(doc(db, "users", userId), dataObj);
      console.log('Link success');
      navigate("/");
    } catch (error) {
      console.log('Hubo un error en la vinculacion de cuenta');
      setErrorMessage('Hubo un error en la vinculacion de cuenta');
    }


    // db.collection("users").doc(props.userId).set(dataObj, { merge: true })
    // .then(() => {
    //     console.log("Document successfully written!");
    //     history.push('/link-success');
    // })
    // .catch((error) => {
    //     console.error("Error writing document: ", error);
    // });

  }

  return (
    <div className='page'>
      <h1>Vincular afiliacion</h1>

      {
        success ?
          <div className='signup-card'>
            <CheckboxPerson24Regular fontSize={36} />

            <h3>¿Eres tu?</h3>

            <p>Confirma que estos datos pertenecen a ti para completar la vinculacion de la cuenta con tu afiliacion de la ANEP.</p>

            <h3>{id}</h3>
            <h4>{name + ' ' + lastname}</h4>
            <p>{institucion}</p>
            <p>{dependencia}</p>
            <p>{puesto}</p>

            <p style={{ color: 'var(--color-error)' }}><small>{errorMessage}</small></p>

            <button className='filled-button' onClick={complete}>Completar vinculacion de cuenta</button>

            <button className='filled-tonal-button' onClick={() => { setSuccess(false) }}>Volver</button>

          </div> :
          <div className='signup-card'>
            <ShareScreenPersonOverlayRegular fontSize={36} />

            <h3>Ingresa tu cedula</h3>

            <p>Para continuar con la verificacion de cuenta debes ingresar tu numero de cedula o identificacion nacional. El numero no debe contener guiones ni espacios.</p>

            <form className='signup-form' onSubmit={request}>
              <div className="input-container signup-email-input">
                <input className="input-normal" id="cedulainput" type="text" value={cedulaInput} onChange={(e) => { setCedulaInput(e.target.value) }} />
                <label className="input-label" htmlFor="cedulainput">Tu cedula</label>
                <p style={{ color: 'var(--color-error)' }}><small>{errorMessage}</small></p>
              </div>
              <button className='filled-button' type='submit' style={{ float: 'right' }}>Consultar</button>
            </form>
          </div>
      }


    </div>
  );
}

function Afiliate() {
  return (
    <div className='page'>
      <div className='signup-card'>
        <NotepadPersonRegular fontSize={36} />
        <h2>Pagina de afiliacion</h2>
        <p>Esta pagina se encuentra en construccion, vuelve en las proximas horas.</p>
        <Link to="/"><button className='filled-button'>Volver a inicio</button></Link>
      </div>
    </div>
  );
}